import './SubjectDashboard.less'

import { Tabs } from 'antd'
import classNames from 'classnames'
import { format } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'

import RecordBackground from '../../assets/images/subject-dashboard-record-background.svg'
import { useScopedIntl } from '../../hooks'
import {
  CommonSurveyStatus,
  EconsentStatus,
  Feature,
  RecordStatus,
  SubjectDashboardRecord,
  fetchEconsentPdfUrl,
  fetchSubjectDashboard,
  generateEconsentPdfFile
} from '../../requests'
import { addHttpPrefix, changeLocale, proxyMinioToLocalhost } from '../../utils'
import { UserContext } from '../auth'
import { DatacIcon, DatacLoading, DatacMessage, DatacTitle } from '../common'
import { Layout } from './Layout'

enum SectionTab {
  Todo = 'todo',
  Done = 'done'
}

interface DashboardRecordRowProps {
  label: string
  value: string
}

const DashboardRecordRow: React.VFC<DashboardRecordRowProps> = ({ label, value }) => {
  const intlLabel = useScopedIntl('subject_dashboard.record.label')
  if (!value) return null
  return (
    <div className="subject-dashboard__record__row">
      <div className="subject-dashboard__record__row__label">{intlLabel(label)}</div>
      <div className="subject-dashboard__record__row__value">{value}</div>
    </div>
  )
}

interface DashboardRecordProps {
  record: SubjectDashboardRecord
  tab: SectionTab
}
const DashboardRecord: React.VFC<DashboardRecordProps> = ({ record, tab }) => {
  const intlSubjectDashboard = useScopedIntl('subject_dashboard')
  const intlPdf = useScopedIntl('studies.econsent.pdf')
  const intlStatus = useScopedIntl('status')
  const intlEconsentStatus = useScopedIntl('studies.econsent.status')
  const type = record.type.toLocaleLowerCase()
  const {
    formName,
    studyName,
    date,
    link,
    status,
    answeredQuestions,
    totalQuestions,
    isArchived,
    isFinished,
    inFuture
  } = record

  const pdfErrorMessage = () => DatacMessage.error(intlPdf('error.title'), intlPdf('error.content'))

  const exportPdf = (exportId: string) => {
    fetchEconsentPdfUrl(
      { fileId: exportId, token: record.token },
      {
        onSuccess: url => window.open(proxyMinioToLocalhost(url)),
        onRequestError: pdfErrorMessage
      }
    )
  }

  const onExport = () => {
    generateEconsentPdfFile(
      { token: record.token },
      {
        onSuccess: pdf => exportPdf(pdf.id),
        onRequestError: pdfErrorMessage
      }
    )
  }

  const onClick = () => {
    if (isArchived) {
      onExport()
      return
    }
    window.open(addHttpPrefix(link))
  }

  const getStatus = () => {
    if (record.type === Feature.SubjectRepository && status === CommonSurveyStatus.Uncompleted) {
      return intlStatus('open')
    }

    switch (status) {
      case CommonSurveyStatus.Resent:
        return intlStatus('open')
      case RecordStatus.NotStarted:
      case RecordStatus.Created:
      case RecordStatus.Scheduled:
      case RecordStatus.Sent:
        return intlStatus('not_started')
      case RecordStatus.Fulfilled:
      case RecordStatus.Signed:
      case RecordStatus.Excluded:
      case RecordStatus.Completed:
      case CommonSurveyStatus.Completed:
        return intlStatus('completed')
      case RecordStatus.Archived:
      case EconsentStatus.Archived:
        return intlEconsentStatus('archived')
      case EconsentStatus.Consented:
        return intlEconsentStatus('consented')
      case EconsentStatus.NeedsCounterSignature:
        return intlEconsentStatus('needs_counter_signature')
      case EconsentStatus.Rejected:
        return intlEconsentStatus('rejected')
      case EconsentStatus.NeedsSignature:
        return intlEconsentStatus('needs_signature')
      default:
        return intlStatus('uncompleted')
    }
  }
  return (
    <div
      onClick={inFuture ? undefined : () => onClick()}
      className={classNames(
        'datac-box',
        'subject-dashboard__record',
        `subject-dashboard__record__${isArchived ? 'archived' : tab}`,
        { 'subject-dashboard__record__future': inFuture }
      )}
    >
      <RecordBackground className="subject-dashboard__record__background" />
      <div className="subject-dashboard__record__header">
        <DatacTitle size="small" className="subject-dashboard__record__title">
          <div>{formName || studyName}</div>
          {intlSubjectDashboard(`feature.${type}`)}
        </DatacTitle>
        {!inFuture && (
          <div className="subject-dashboard__record__type">
            {isArchived && <DatacIcon name="trash" type="grey" raw />}
            {!isArchived && isFinished && <DatacIcon name="check" type="grey" raw />}
            {!isArchived && !isFinished && <DatacIcon name="arrowUpRight" type="grey" raw />}
          </div>
        )}
      </div>
      <DashboardRecordRow label="date" value={format(date, `dd MMM yyyy${inFuture ? ', HH:mm' : ''}`)} />
      <DashboardRecordRow label="status" value={getStatus()} />
      {answeredQuestions != null && (
        <DashboardRecordRow label="questions" value={`${answeredQuestions}/${totalQuestions}`} />
      )}
    </div>
  )
}

interface DashboardSectionProps {
  records: SubjectDashboardRecord[]
  tab: SectionTab
}
const DashboardSection: React.VFC<DashboardSectionProps> = ({ records, tab }) => {
  const intlSection = useScopedIntl('subject_dashboard.section')

  if (!records?.length) {
    return <div className="subject-dashboard__no-records">{intlSection(`${tab}.no_records`)}</div>
  }

  return (
    <div className="subject-dashboard__section">
      {records.map((record, index) => (
        <DashboardRecord record={record} key={index} tab={tab} />
      ))}
    </div>
  )
}

export const SubjectDashboard: React.VFC = () => {
  const intlSubjectDashboard = useScopedIntl('subject_dashboard')
  const intlSubtitle = useScopedIntl('subject_dashboard.subtitle')
  const intl = useScopedIntl('')
  const [records, setRecords] = useState<Record<SectionTab, SubjectDashboardRecord[]>>(null)
  const [isFetching, setIsFetching] = useState(true)
  const [needsBankDetails, setNeedsBankDetails] = useState(false)
  const [selectedTab, setSelectedTab] = useState<SectionTab>(SectionTab.Todo)
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (user?.language && !isFetching) changeLocale(user.language)
  }, [user.language, isFetching])

  useEffect(() => {
    setIsFetching(true)
    return fetchSubjectDashboard({
      onSuccess: records => {
        setNeedsBankDetails(records.some(record => record.bankDetailsValidation))
        setRecords({
          [SectionTab.Todo]: records.filter(record => !record.isFinished && !record.isArchived),
          [SectionTab.Done]: records.filter(record => record.isFinished || record.isArchived)
        })
        setIsFetching(false)
      },
      onRequestError: code => {
        setIsFetching(false)
        DatacMessage.genericError(intl, code)
      }
    })
  }, [])

  const tabName = (tab: SectionTab) => (
    <div className="subject-dashboard__tab">
      {intlSubjectDashboard(`section.${tab}`)}
      <span className="subject-dashboard__tab__bullet">&bull;</span>
      {records?.[tab].length}
    </div>
  )

  const getSubtitle = () => {
    if (isFetching) return ''
    const count = records[SectionTab.Todo]?.length

    if (!count) return intlSubtitle('none')

    const countString = count > 1 ? intlSubtitle('many', { count }) : intlSubtitle('one')
    return (
      <>
        {intlSubtitle('start')} <span>{countString}</span> {intlSubtitle('end')}
      </>
    )
  }

  return (
    <Layout subtitle={getSubtitle()} needsBankDetails={needsBankDetails}>
      <div className="subject-dashboard">
        <DatacLoading isLoading={isFetching}>
          <Tabs activeKey={selectedTab} onChange={newTab => setSelectedTab(newTab as SectionTab)}>
            <Tabs.TabPane key={null} tab={intlSubjectDashboard('tasks')} disabled />
            <Tabs.TabPane key={SectionTab.Todo} tab={tabName(SectionTab.Todo)} />
            <Tabs.TabPane key={SectionTab.Done} tab={tabName(SectionTab.Done)} />
          </Tabs>
          <DashboardSection records={records?.[selectedTab]} tab={selectedTab} />
        </DatacLoading>
      </div>
    </Layout>
  )
}
