import React from 'react'

import { SubjectDashboard } from '../../components/SubjectDashboard'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { AccountType } from '../../requests'

const IndexPage = () => {
  return (
    <AuthRoute accountType={AccountType.Subject}>
      <>
        <SessionTimeoutModal accountType={AccountType.Subject} />
        <SubjectDashboard />
      </>
    </AuthRoute>
  )
}

export default IndexPage
export const Head = () => <title>Datacapt - Subject dashboard</title>
